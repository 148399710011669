<template>
  <v-card flat>
    <v-container>
      <v-row>
        <v-col class="text-right" cols="12">
          <v-btn @click="resetEverything" depressed text color="success">Reset</v-btn>
          <v-btn class="ma-3" @click="cancelEverything" depressed outlined color="error">Cancel</v-btn>
          <v-btn
            @click="saveEverything()"
            :loading="loading"
            outlined
            depressed
            color="success"
          >Save</v-btn>

          <!-- Loading -->
          <v-progress-linear :indeterminate="loading"></v-progress-linear>

          <!-- Snackbar  -->
          <v-snackbar v-model="snackbar" :color="snackbarColor">
            {{ snackbarMessage }}
            <v-btn dark text @click="snackbar = false">Close</v-btn>
          </v-snackbar>
          <!-- /Snackbar  -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-text-field
            @change="generateSlug"
            v-model="newCategory.name"
            name="name"
            label="Name"
            class="input-group--focused"
            single-line
          ></v-text-field>
          <v-textarea
            v-model="newCategory.description"
            label="Category Description"
            name="name"
            auto-grow
          ></v-textarea>
        </v-col>
        <v-col cols="4">
          <v-img
            v-if="newCategory.image == null"
            pa-3
            height="200"
            contain
            @click="selectImage"
            :src="require('@/assets/admin/media/files/photo-camera.svg')"
          ></v-img>
          <input
            type="file"
            ref="uploadImage"
            @change="uploadImage"
            style="display:none"
            accept=".png, .jpg, .jpeg"
          />

          <v-img v-if="newCategory.image" height="200" pa-3 :src="newCategory.image">
            <v-btn dark red position-y icon @click="deleteImage(newCategory.image)" color="danger">
              <v-icon>delete_forever</v-icon>
            </v-btn>
          </v-img>
          <v-switch
            class="justify-center"
            v-model="newCategory.active"
            color="success"
            label="Active"
          ></v-switch>

          <!-- Product Image Uploading Progress Start -->
          <v-dialog v-model="uploadingImage" hide-overlay persistent width="300">
            <v-card color="primary" dark>
              <v-card-text>
                {{ progress }} % Uploading Image..
                <v-progress-linear :value="progress" color="white" class="mb-0"></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
          <!-- /Product Image Uploading Progress end -->
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-toolbar flat dark color="purple darken-2" extended></v-toolbar>
          <v-card v-if="isFeatureUpdate" class="mx-auto" max-width="500" style="margin-top: -64px;">
            <v-toolbar flat>
              <v-toolbar-title>Features</v-toolbar-title>
              <div class="flex-grow-1"></div>
              <v-btn @click="cancelFeature()" text color="success">Cancel</v-btn>
              <v-btn @click="saveFeature()" text color="success">Save</v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-text-field
                outlined
                :rules="[rules.required]"
                v-model="feature.name"
                label="Name of Feature"
              ></v-text-field>
              <!-- Combobox Start -->
              <v-combobox
                :rules="[rules.required]"
                outlined
                v-model="feature.options"
                :items="feature.options"
                :search-input.sync="search"
                hide-selected
                label="Add some tags"
                multiple
                persistent-hint
                small-chips
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "
                        <strong>{{ search }}</strong>". Press
                        <kbd>enter</kbd> to create a new one
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-card-text>
          </v-card>
          <v-card v-else class="mx-auto" max-width="500" style="margin-top: -64px;">
            <v-toolbar flat>
              <v-toolbar-title>Features</v-toolbar-title>
              <div class="flex-grow-1"></div>
              <v-btn @click="isFeatureUpdate = true" icon>
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-list>
                <v-list-item v-for="(item, index) in newCategory.features" :key="index">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-btn @click="editFeature(item, index)" icon color="success">
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-icon>
                    <v-btn @click="deleteFeature(index)" icon color="error">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import firebase from "firebase";
import { mapGetters, mapActions } from "vuex";
import { watch } from "fs";

export default {
  name: "AddProductCategory",
  data() {
    return {
      // Combobox Data
      search: null,
      // Active Features Update or add mode
      isFeatureUpdate: false,
      progress: 0, // Image Uploading Progress
      uploadingImage: false, // Popup for image uploading progress
      loading: false, // Loading
      // Snackbar
      snackbar: false,
      snackbarMessage: null,
      snackbarColor: "",
      // Form Data
      rules: {
        required: value => !!value || "Required."
      },
      feature: {
        name: "",
        options: []
      },
      featureIndex: null,
      newCategory: {
        name: "",
        description: "",
        active: true,
        image: null,
        slug: "",
        features: []
      }
    };
  },

  methods: {
    generateSlug() {
      const slugData = this.newCategory.name;
      return (this.newCategory.slug = this.slugify(slugData));
    },
    ...mapActions(["add"]),
    // Save Feature to Data (feature )
    saveFeature() {
      if (this.feature.name !== "" && this.feature.options.length != 0) {
        if (this.featureIndex === null) {
          this.newCategory.features.push(this.feature);
        } else {
          this.newCategory.features[this.featureIndex] = this.feature;
        }
        this.feature = {
          name: "",
          options: []
        };
        this.featureIndex = null;
        this.isFeatureUpdate = false;
      } else {
        this.error = true;
      }
    },
    cancelFeature() {
      this.feature = {
        name: "",
        options: []
      };
      this.featureIndex = null;
      this.isFeatureUpdate = false;
    },
    // Delete Feature to Data (newCategory.feature )
    deleteFeature(index) {
      this.newCategory.features.splice(index, 1);
    },
    // Edit Feature to Data (newCategory.feature )
    editFeature(item, index) {
      this.feature = item;
      this.featureIndex = index;
      this.isFeatureUpdate = true;
    },
    selectImage() {
      this.$refs.uploadImage.click();
    },

    uploadImage(image) {
      if (image.target.files[0]) {
        this.uploadingImage = true;
        this.loading = true;
        // console.log(image.target.files[0])
        let file = image.target.files[0];
        let time = Date.now();
        // File or Blob named mountains.jpg

        let storageRef = firebase
          .storage()
          .ref("product_categories/" + time + "_" + file.name);

        // Create the file metadata
        let metadata = {
          contentType: "image/jpeg"
        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        let uploadTask = storageRef.put(file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          snapshot => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log("Upload is running");
                break;
            }
          },
          error => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              // console.log('File available at', downloadURL);
              this.newCategory.image = downloadURL;
              this.loading = false;
              this.uploadingImage = false;
            });
          }
        );
      }
    },
    deleteImage(imageUrl) {
      this.loading = true;
      // console.log(imageUrl)
      // Get Image URL and Reff in firebase storage
      let image = firebase.storage().refFromURL(imageUrl);
      //Remove from Data: newProduct.images[]
      this.newCategory.image = null;

      // Delete the file from Firebase Storage
      image
        .delete()
        .then(() => {
          // File deleted successfully
          this.snackbarMessage = "Image deleted successfully";
          this.snackbarColor = "success";
          this.snackbar = true;
          this.loading = false;
        })
        .catch(error => {
          // Uh-oh, an error occurred!
          this.snackbarMessage = "Uh-oh, an error occurred!";
          this.snackbarColor = "error";
          this.snackbar = true;
          this.loading = false;
        });
    },
    slugify(text, ampersand = "and") {
      const a = "àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
      const b = "aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
      const p = new RegExp(a.split("").join("|"), "g");

      return text
        .toString()
        .toLowerCase()
        .replace(/[\s_]+/g, "-")
        .replace(p, c => b.charAt(a.indexOf(c)))
        .replace(/&/g, `-${ampersand}-`)
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-")
        .replace(/^-+|-+$/g, "");
    },
    cancelEverything() {
      this.resetEverything();
      this.goBack();
    },
    resetForm() {
      this.newCategory = {
        name: "",
        description: "",
        active: true,
        image: null,
        slug: "",
        features: []
      };
    },
    saveEverything() {
      this.loading = true;
      if (this.newCategory.name != "") {
        return this.add({
          collection: "productCategories",
          data: this.newCategory
        })
          .then(data => {
            this.resetForm();
            this.loading = false;
            this.snackbarMessage = "Created new category";
            this.snackbarColor = "success";
            this.snackbar = true;
            // this.goBack()
          })
          .catch(error => {
            this.loading = false;
            this.snackbarMessage = error;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      } else {
        this.loading = false;
        this.snackbarMessage = "Oops! Not provided name of the category";
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },
    resetEverything() {
      if (this.newCategory.image != null) {
        this.deleteImage(this.newCategory.image);
      }
      this.resetForm();
      this.snackbarMessage = "Everything Reseted";
      this.snackbarColor = "red";
      this.snackbar = true;
    },
    goBack() {
      this.$router.replace("/admin/products/category/list");
    }
  }
};
</script>

<style></style>
